import React from 'react'
import config from '../../../config'
import styles from './Footer.module.sass'

const Footer = () => {
  return (
    <footer className={styles['footer']}>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
          Copyright {config.companyName} © {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
